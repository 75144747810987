import { Component, Host, Prop, h } from '@stencil/core';
import axios from 'axios';
import serialize from 'form-serialize';
import { paginationStore } from '../../stores';

@Component({
  tag: 'x-filters',
})
export class XFilters {
  @Prop() endpoint: string;
  @Prop() method: 'post' | 'put' = 'post';
  @Prop() autosave: boolean;

  private submit = form => {
    paginationStore.set('isLoading', true);

    const serialized = serialize(form, { hash: true, empty: true });
    const data = this.method === 'put' ? Object.values(serialized)[0] : serialized;

    axios[this.method](this.endpoint, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        document.querySelector('x-pagination-context[target]').innerHTML = res.data;

        const nextCurrent = res.headers['x-current-page'];
        const nextSize = res.headers['x-page-size'];
        const nextTotal = res.headers['x-total-pages'];
        const nextTotalRecords = res.headers['x-total-records'];

        if (nextCurrent) paginationStore.set('currentPage', parseInt(nextCurrent, 10));
        if (nextSize) paginationStore.set('pageSize', parseInt(nextSize, 10));
        if (nextTotal) paginationStore.set('totalPages', parseInt(nextTotal, 10));
        if (nextTotalRecords) paginationStore.set('totalRecords', parseInt(nextTotalRecords, 10));
      })
      .finally(() => {
        paginationStore.set('isLoading', false);
      });
  };

  private handleSubmit = e => {
    e.preventDefault();

    if (!this.autosave) {
      this.submit(e.target);
    }
  };

  private handleChange = e => {
    if (this.autosave) {
      this.submit(e.currentTarget);
    }
  };

  render() {
    return (
      <Host>
        <form onSubmit={this.handleSubmit} onChange={this.handleChange}>
          <slot></slot>
        </form>
      </Host>
    );
  }
}
